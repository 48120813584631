import React from "react";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import SeoTermsOfService from "../components/SeoTermsOfService";
import CookieBanner from "../components/CookieBanner";

const TermsOfServicePage = () => {
  return (
    <>
      <div id="top" name="top" className="bg-gray">
        <SeoTermsOfService />
        <Header />
        <main className="container flex flex-col h-fit w-full py-10 md:py-10 xl:py-10 ">
          <div className="flex flex-col gap-5 text-center mb-10">
            <h1 className="text-4xl">Terms of Service</h1>
            <h4 className="text-xl">Updated November 3, 2022</h4>
          </div>

          <div className="mb-10">
            <p className="body2">
              The following Terms of Service are valid from and were last updated on November 3, 2022.
            </p>
            <p className="body2">
              WIN GRAVITY SRL, Dacia Street 1, 700259, Iasi, Romania, Managing Directors: Corina Craescu, Daniel
              Berigoi, VAT-ID: RO37233986 (hereinafter referred to as "Slashscore" or "us") operates a real-time
              profiling platform for software developers via&nbsp;
              <a href="https://app.slashscore.com/" className="underline" target="_blank" rel="noreferrer">
                https://app.slashscore.com/
              </a>
              &nbsp; and other channels (the "Service").
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">1. Scope</h2>
            <p className="body2">
              After you connect your third-party accounts (like Gitlab, Github, Bitbucket, Stack Overflow, Meetup,
              Medium, or others), Slashscore automatically calculates your professional, extra, community, and
              popularity scores. After the profile is complete, including integrations, Slashscore allows the Software
              Developers to participate in the Service.
            </p>

            <p className="body2">
              Slashscore only offers services to Software Developers based on these Terms of Service, which are also
              called the "Developers Terms of Service" or just "Terms of Service."
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">2. Software Developers Eligible to Participate</h2>
            <p className="body2">Slashscore offers the Service only to Software Developers. </p>
            <p className="body2">
              With their account registration or participation in the Service, Developers represent that they are of
              legal age and of legal capacity.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">3. Developers Enrolment </h2>
            <p className="body2">
              Participation in the Service requires that the Software Developers enter a binding agreement with
              Slashscore as defined below in Section 3 and the setup of a Developer account ( "Account" ).
            </p>
            <p className="body2">
              Software Developers must connect their third-party accounts and complete their profiles to participate in
              the Service on Slashscore&apos;s platform.
            </p>
            <p className="body2">
              Software Developers must accurately complete their profiles and connect their accounts and not use any
              aliases or other means to mask their true identity or contact information.
            </p>
            <p className="body2">
              By connecting their accounts and completing their profiles, Developers submit a binding offer to enter
              into an agreement with Slashscore according to the terms and conditions of these Terms of Service (
              "Agreement" ).
            </p>
            <p className="body2">
              Slashscore is entitled but not obliged to verify the accuracy of the Developer's information. This may be
              done by Slashscore requesting documents to prove the identity.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">4. Limits of Use / Prohibited Conduct</h2>
            <p className="body2">
              Slashscore provides the Service solely for use in accordance with these Terms of Service. Automated login
              is prohibited. Only the official clients and apps or websites provided by Slashscore may be used to
              connect to the Service. The Software Developers may not create, support, host, link, or provide any other
              options, which can be used by other persons to utilize the Service.
            </p>
            <p className="body2">
              The Software Developers are prohibited from transferring their Accounts to third parties without the prior
              written consent of Slashscore.
            </p>
            <p className="body2">
              The Sofware Developers are not entitled to use any means, mechanisms, or software in connection with the
              Service, which can disturb the functions of the Service. The Software Developers may not undertake any
              action, which can result in an unreasonable or excess load of the technical capacities of Slashscore. In
              particular, the Developer is not permitted to block, overwrite or modify the contents generated by
              Slashscore unless Slashscore has permitted it specifically in writing.
            </p>
            <p className="body2">
              The Software Developer will refrain from anything that could endanger the performance and availability of
              the Service.
            </p>
            <p className="body2">
              By using the Service, the Software Developer will not violate any applicable legal provisions such as the
              criminal code or third party rights and the Developer assures not to contribute or transmit any illegal or
              immoral contents to the Service. This includes specifically any content (including member names, etc.),
            </p>
            <ul className="list-disc pl-14">
              <li className="body2">which are false, inaccurate, or misleading;</li>
              <li className="body2">which are insulting, racist, sexist, pornographic, or obscene;</li>
              <li className="body2">which can damage the reputation of Slashscore;</li>
              <li className="body2">
                suitable to violate copyrights, patents, brands, or any other intellectual property rights, the rights
                on a person's own image, and other personal rights or third-party rights.
              </li>
            </ul>
            <p className="body2">
              The Software Developer is not permitted to disseminate commercial advertising for third-party products or
              programs, to send unsolicited mass emails, and unsolicited advertising in the Service. The Developer will
              honor the privacy of third parties. In addition, the Developer is not permitted to disseminate in the
              Service unauthorized any third-party work that is protected by copyrights or other rights. Moreover, the
              Developer is not allowed to refer to offers with such content.
            </p>
            <p className="body2">
              Slashscore is entitled to stop such violations against this Section 4. In addition, Slashscore is entitled
              to delete the breaching contents of the Developers. The same applies to definite indications of a
              violation against these Terms of Service to contents, which are otherwise illegal. There is no entitlement
              to the recovery of breaching deleted content. Such a claim is also voided if the restoration is not
              possible for technical reasons.
            </p>
            <p className="body2">
              Without the prior express written consent of Slashscore, any blocked Developer is prohibited from creating
              a new account. If this ban was circumvented, then Slashscore has the right to block this Account
              permanently without prior announcement.
            </p>
            <p className="body2">
              The terms of this Section 4 don't limit Slashscore's right to end the agreement, especially its right to
              end it right away, as stated in Section 12.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">5. Slashscore&apos;s responsibilities</h2>
            <p className="body2">
              Slashscore provides the Service. The Service works as follows: Developers sign-up for the Service, connect
              their third-party accounts, and complete their profiles and other information as specified in the Terms of
              Service or the Developer Terms of Service available.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">6. Obligations of Developers</h2>
            <p className="body2">
              Developers must connect their third-party accounts that are needed to calculate the scores and fill out
              the required and optional fields on their profile forms with the information about those accounts.
              Slashscore may add/remove compulsory or voluntary fields from time to time. The Developer shall not
              transmit any personal data of third parties (i.e., data allowing identification of an individual) to
              Slashscore unless data protection laws allow for such transmission or the Developer has obtained consent
              from the concerned parties.
            </p>
            <p className="body2">
              Unless otherwise provided in these Terms of Service, Slashscore will usually communicate with the
              Developers via email. The Developer shall make sure that it receives all emails sent by Slashscore to the
              address submitted during the sign-up, or at a later date. The Developer will in particular configure the
              spam filter accordingly and regularly check all incoming emails under this address. Slashscore may choose
              any other appropriate means of communication.
            </p>
            <p className="body2">
              The Developer shall keep all access data (login, passwords, etc.) for the Service ( "Access Data" )
              strictly confidential. The Developer shall promptly inform Slashscore in case it learns or suspects that
              an unauthorized third person is in possession of the Access Data.
            </p>
            <p className="body2">
              n the event that Slashscore has reason to believe that an unauthorized third party is in possession of
              Access Data, Slashscore may, without assuming any responsibility to do so and always acting in its sole
              discretion, change the Access Data without prior notice or block the respective account. Slashscore will
              promptly inform the Developer and will, upon request, communicate the new Access Data to the Developer
              without undue delay. The Developer cannot claim to have its initial Access Data restored.
            </p>
            <p className="body2">
              In the event that a third party uses Access Data, with the Developer at fault, the Developer&apos;s Access
              Data, the Software Developer is liable for all such actions, and damages. In such an event, all access
              through the Developer&apos;s Access Data shall be considered an access by the Developer.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">7. Payment</h2>
            <p className="body2">The use of Slashscore is free for Software Developers.</p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5"> 8. Quality of the Service / Technical Deficiencies</h2>
            <p className="body2">
              Our Service is continuously advanced and updated. By changing and advancing the Service, Slashscore
              intends to provide Developers with a useful Service and a pleasant experience. Therefore, Slashscore
              grants the Developer access to the Service in its available form or version. Changes and advancements can
              affect system requirements and compatibility requirements. The Developer has no claim for maintaining or
              reconstructing a specific condition of the scope of services. Any of the Developer's claims for
              deficiency, which refer to the technical usability of the Service itself, shall remain unaffected thereby.
              Due to the continuous advancement of the Service, Slashscore reserves itself the right to offer new
              features and/or remove them from the Service or change them. The Developer consents to these continuous
              changes to the Service.
            </p>
            <p className="body2">
              Excluded from warranty are generally such errors, which occur through external influences such as
              operating errors by the Developer, force majeure, or any modifications, not carried out by Slashscore or
              any other manipulations.
            </p>
            <p className="body2">
              Slashscore does not assume any guarantees in a legal sense as long as they are not expressly provided for
              in these Terms of Service.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">9. Availability</h2>
            <p className="body2">
              Slashscore shall be liable under the terms of this Agreement only in accordance with the provisions set
              out in Section 8. Except in cases of intent or gross negligence, any liability of Slashscore shall be
              disclaimed. The same applies in cases of slight negligence by agents or assistants in performance.
              However, the foregoing does not apply in the following cases:
            </p>
            <ul className="list-disc pl-14">
              <li className="body2">
                Slashscore shall be unrestrictedly liable for losses caused intentionally or with gross negligence by
                Slashscore, its legal representatives, or senior executives and for losses caused intentionally by other
                assistants in performance.
              </li>
              <li className="body2">
                Slashscore shall be unrestrictedly liable for death, personal injury, or damage to health caused by the
                intent or negligence of Slashscore, its legal representatives, or assistants in performance.
              </li>
              <li className="body2">
                If Slashscore provides a warranty it shall be liable for losses arising from the lack of any warranted
                characteristics up to the amount which is covered by the purpose of the warranty and which was
                foreseeable for Slashscore at the time the warranty was given.
              </li>
              <li className="body2">
                Slashscore shall be liable for losses caused by the negligence (including simple negligence) or
                intentional breach of its primary obligations by Slashscore, its legal representatives, or assistants in
                performance.
              </li>
              <ul className="list-circle pl-20">
                <li className="body2">
                  Primary obligations are such basic duties, which form the essence of the Agreement, which were
                  decisive for the conclusion of the Agreement and on the performance upon which the Developer may rely
                  upon.
                </li>
                <li className="body2">
                  If Slashscore breaches its primary obligations through simple negligence, then its ensuing liability
                  shall be limited to the amount which was foreseeable by Slashscore at the time the respective service
                  was performed.
                </li>
              </ul>
            </ul>
            <p className="body2">
              Slashscore undertakes to assure an availability of the Service of 99% (ninety-nine percent) as a yearly
              average. Periods during which the Service is not available because of technical or other problems outside
              Slashscore&apos;s This doesn't include things out of your control, like "force majeure" or a mistake made
              by a third party, or times when regular maintenance is being done. Slashscore may restrict access to the
              Service if required for network security, maintenance of network integrity, and the prevention of severe
              malfunction of the network, the software, or stored data. The Developer&apos;s rights in cases of intent
              or gross negligence remain unaffected.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">10. Intellectual Property</h2>
            <p className="body2">
              Slashscore or its respective licensor is the exclusive owner of the rights of all contents of the Service.
              The content is protected by national and international law, particularly by copyright. The unauthorized
              dissemination, copying, deletion, or any other violation of intellectual property rights and copyrights of
              Slashscore are prosecuted under both civil and criminal law.
            </p>
            <p className="body2">
              All rights not expressly granted in these Terms of Service are reserved by Slashscore. Slashscore will
              retain all rights, titles, and interests in and to the Service (except for any licensed content and
              third-party content included therein), including all data (such as any usage data and compilations
              thereof), information, and software related thereto. The Developer acknowledges that the software,
              information, content, and data related to the Service (such as any usage data or compilations thereof) are
              protected for Slashscore under copyright and similar rights and may contain trade secrets or other
              intellectual or industrial property owned or licensed by Slashscore.
            </p>
            <p className="body2">
              Developers warrant that they have the right (i) to submit the information to the Service and (ii) can
              grant Slashscore the right to use the information as described above.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">11. Confidentiality</h2>
            <p className="body2">
              Except as otherwise provided in these Terms of Service or with the consent of Slashscore, the Developer
              agrees that all information, including, without limitation, business information and information about
              tech stack concerning Slashscore shall remain strictly confidential and secret and shall not be utilized,
              directly or indirectly, by Developer for any purpose other than the Developer&apos;s participation in the
              Service ( "Confidential Information" ).
            </p>
            <p className="body2">
              Developers shall especially not use any information obtained from the Service to develop, enhance, or
              operate a service that competes with the Service, or assist another party to do the same.
            </p>
            <p className="body2">
              After and during the term of the Agreement, neither party will use for any purpose or disclose to any
              third party any Confidential Information of the other party. Any exception to this must be obtained in
              advance.
            </p>
            <p className="body2">
              The foregoing restriction does not apply to information that has been developed independently by the
              receiving party without access to the other party&apos;s Confidential Information or has been rightfully
              received from a third party authorized to make such disclosure or has been approved for release in writing
              by the disclosing party or has become publicly known through no breach of this Section by the receiving
              party or is required to be disclosed by a competent legal or governmental authority, provided that the
              receiving party gives the disclosing party prompt written notice of such requirement prior to disclosure
              and assists in obtaining an order to protect the information from public disclosure.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">12. Termination</h2>
            <p className="body2">
              The Agreement shall commence on the date of creating a Slashscore profile and shall continue thereafter
              until terminated as provided herein.
            </p>
            <p className="body2">
              Section 11 shall survive any termination of the Agreement Section 11 for five years.
            </p>
            <p className="body2">The parties remain free to terminate the Agreement for cause at any time. </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">13. Data Protection</h2>
            <p className="body2">
              Slashscore processes and utilizes the Developer's data collected during the performance of the Agreement
              and within the framework of These Terms of Service, specifically to the extent necessary to fulfill the
              agreement properly, in accordance with all applicable data protection provisions.
            </p>
            <p className="body2">
              The Developer understands that the performance of the Agreement includes the making available of personal
              data to third parties with which Slashscore cooperates subject to these Terms of Service and the Privacy
              Policy.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">14. Changes to the Terms of Service</h2>
            <p className="body2">Each login to the Service is subject to these Terms of Service.</p>
            <p className="body2">
              Slashscore may make changes to these Terms of Service (including amendments) at any given time, in the
              future, if this should prove necessary (in particular to reflect changes in the Service or changes in the
              legal framework applicable to it, such as new legislation or case-law) and provided the Developer is not
              disadvantaged contrary to good faith.
            </p>
            <p className="body2">
              The Developer will be notified of changes to the Terms of Service in the appropriate form. Slashscore will
              notify the Developer of the Service, or via email. Changes to the Terms of Service will always be
              highlighted upon the first login after the changes or amendments have been made.
            </p>
            <p className="body2">
              The Developer may dispute changes to the Agreement within a time period of six weeks following receipt of
              the notification of the changes and the possibility of taking notice thereof. It is recommended that the
              Developer submits their opposition in writing (for example via email).
            </p>
            <p className="body2">
              The changes to the Terms of Service become binding in the event that the Developer (i) does not dispute
              the changes within the above-mentioned time period or (ii) continues to use the Service after the
              above-mentioned period.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">15. Governing Law / Jurisdiction</h2>
            <p className="body2">
              This Agreement shall be governed by and construed in accordance with the laws of Romania.
            </p>
          </div>

          <div className="mb-10">
            <h2 className="text-2xl mb-5">16. General Provisions</h2>
            <p className="body2">
              The Agreement contains the entire agreement between Slashscore and Developer with respect to the subject
              matter hereof, and supersedes all prior and/or contemporaneous agreements or understandings, written or
              oral. Terms and conditions of the Developer do not become part of the Agreement unless Slashscore has
              accepted them in writing.
            </p>
            <p className="body2">
              All or any of Slashscore&apos;s rights and obligations under the Agreement may be assigned to a subsequent
              owner or operator of the Service in a merger, acquisition, or sale of all or substantially all of
              Slashscore&apos;s assets. The Developer must not assign or transfer the Agreement or any or all of its
              rights thereunder without the prior written consent of Slashscore. This Agreement will be binding on and
              inure to the benefit of the legal representatives, successors, and valid assigns of the parties hereto.
            </p>
            <p className="body2">
              Except as set forth in Section 14 above, the Agreement may not be modified without the prior written
              consent of both parties. Any changes, amendments, or abrogation of the Agreement (partly or entirely)
              require written form (letter, fax, or email); the requirement of written form can only be waived in
              written form.
            </p>
            <p className="body2">
              Each party to this Agreement is an independent contractor in relation to the other party with respect to
              all matters arising under this Agreement.
            </p>
            <p className="body2">The English version of these Terms of Service is decisive.</p>
            <p className="body2">
              By creating a profile and joining the Service, the Developer affirms and acknowledges that they have read
              these Terms of Service in their entirety and agrees to be bound by all of their terms and conditions. If
              the Developer does not wish to be bound by these Terms of Service, the Developer should not create an
              account to join the Service.
            </p>
          </div>
        </main>
        <Footer arrowLink="/terms-of-service#top" />
        <CookieBanner />
      </div>
    </>
  );
};

export default TermsOfServicePage;
